// 加解密
const encryption = false;
// 路由权限
const routerFlag = false;

// let baseURL = "https://coingate-order.qlclient.com"
let baseURL = "https://demo-api.hyperhashing.com"

const version = "V1";
export { encryption, routerFlag, version, baseURL };
