import { createStore } from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

const state = {
  token: localStorage.getItem("token") || "",
  userInfo: localStorage.getItem("userInfo")||"",
  system: {}
};


export default {
  state,
  getters,
  actions,
  mutations,
};
