import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/css/global.css";
import "@/assets/css/element.scss";
import http from "@/http/api.js";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import { getlocalStorage, setlocalStorage, remlocalStorage } from './Utils/localStorage'

const app = createApp(App);
app.config.globalProperties.$http = http;
app.config.globalProperties.$getlocalStorage = getlocalStorage;
app.config.globalProperties.$setlocalStorage = setlocalStorage;
app.config.globalProperties.$remlocalStorage = remlocalStorage;
app.use(store).use(ElementPlus).use(router).mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
