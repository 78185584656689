<template>
  <router-view v-if="isRouterAlive" />
</template>

<script setup>
import { ref, onMounted, nextTick, provide } from "vue";

let isRouterAlive = ref(true);
const reload = () => {
  isRouterAlive.value = false;
  nextTick(() => {
    console.log(isRouterAlive.value);
    isRouterAlive.value = true;
  });
};
provide("reload", {
  reload,
});
</script>

<style lang="scss">
#app {
  font-family: Helvetica Neue, Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #eee;
  
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  /* 滚动条上的滚动滑块 */
  // ::-webkit-scrollbar-thumb {
  //   background-color: #aaa;
  //   border-radius: 32px;
  // }

  /* 滚动条轨道 */
  // ::-webkit-scrollbar-track {
  //   background-color: #dbeffd;
  //   border-radius: 32px;
  // }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
