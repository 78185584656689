import request from "../request";
export default {
  createOrder(data) {
    return request({
      url: "/api/createOrder",
      method: "post",
      data,
    });
  },
};
