import { createStore } from 'vuex'
import Login from './login'


export default createStore({
 
  modules: {
    Login
  }
})
