// 解密获取
function getlocalStorage(name) {
  return localStorage.getItem(name)
  // if (process.env.NODE_ENV === 'development') {
  //   return JSON.parse(localStorage.getItem(name))
  // } else {
  //   const newName = window.btoa(encodeURIComponent(name))
  //   const data = localStorage.getItem(newName)
  //   if (data) {
  //     return decodeURIComponent(window.atob(data))
  //   } else {
  //     return data
  //   }
  // }
}
// 加密储存
function setlocalStorage(name, data) {
  localStorage.setItem(name, data)
  // if (process.env.NODE_ENV === 'development') {
  //   // 只有本身不是json格式才需要转json
  //   let JsonData = isJSON(data) ? data : JSON.stringify(data);
  //   localStorage.setItem(name, JsonData)
  // } else {
  //   if (data) {
  //     localStorage.setItem(window.btoa(encodeURIComponent(name)), window.btoa(encodeURIComponent(data)))
  //   } else {
  //     localStorage.setItem(window.btoa(encodeURIComponent(name)), data)
  //   }
  // }
}
// 删除 储存
function remlocalStorage(name) {
  localStorage.removeItem(name)
  // if (process.env.NODE_ENV === 'development') {
  //   localStorage.removeItem(name)
  // } else {
  //   const newName = window.btoa(encodeURIComponent(name))
  //   localStorage.removeItem(newName)
  // }
}
//判断是否是json对象
var isJSON = function (str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }

    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
}

export { getlocalStorage, setlocalStorage, remlocalStorage } 