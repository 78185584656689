<template>
  <div style="display: flex; justify-content: center; margin-top: 70px">
    <div
      style="
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 500px;
      "
    >
      <el-form :model="form" label-width="180px">
        <el-form-item label="Symbol">
          <el-select v-model="form.symbol" placeholder="Select">
            <el-option label="BTC" value="btc"></el-option>
            <el-option label="ERC20-USDT" value="erc20-usdt"></el-option>
            <el-option label="ERC20-USDC" value="erc20-usdc"></el-option>
            <el-option label="TRC20-USDT" value="trc20-usdt"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="Email">
          <el-input v-model="form.email"></el-input>
        </el-form-item> -->
        <el-form-item label="USD Amount">
          <el-input
            style="width: 100%"
            v-model="form.usd_price"
          ></el-input>
        </el-form-item>
        <el-form-item label="Payer ID">
          <el-input v-model="form.playerId"></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: center">
          <el-button style="width: 200px" @click="sendRequest" type="primary"
            >Submit</el-button
          >
        </div>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { base64To } from "../Utils/Aes";
import { getCurrentInstance, onMounted, ref, computed } from "vue";
const { appContext } = getCurrentInstance();
const proxy = appContext.config.globalProperties;

let hash = ref("");
let form = ref({
  symbol: "",
  playerId: "",
  email: "1@qq.com",
  usd_price: "",
  type: "price",
  orderId: "",
  timestamp: "",
});
const sendRequest = async () => {
  form.value.orderId= Math.floor(Date.now() / 1000)
  form.value.timestamp = Math.floor(Date.now() / 1000)
  form.value.usd_price = Number(form.value.usd_price);
  let data = {
    merchant_id: "a56e0-0a10c-73015-9f330",
    hash: base64To(form.value),
    data: form.value,
  };
  let res = await proxy.$http.createOrder( data );
  if (res.code == 200) {
    window.location.replace(res.data.url);
    
  }
};

</script>

<style lang="scss" scoped>
::v-deep .el-input-number .el-input__inner {
  text-align: left !important;
}
</style>
