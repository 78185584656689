export default {
  addTokenFunc(state, token) {
    state.token = token;
    localStorage.setItem("token",token)
  },
  deleteTokenFunc(state) {
    state.token = "";
  },
  addUserFunc(state, userInfo) {
    state.userInfo = userInfo;
    // localStorage.setItem("userInfo", userInfo)
  },
  deleteUserFunc(state) {
    state.userInfo = "";
  },
  addSystem(state, system) {
    state.system = system;
  },
};
