import md5 from '../Utils/md5';
import request from './request';

// 获取服务端公匙
function getPublicKey(params) {
  return request({
    url: '/api/getPubKey',
    params
  })
}

// 判断是哪种加密传输
async function funEncryption(options) {
  // console.log(options)
  let server_public_key // 服务端公钥
  let server_public_key_md5// 服务端公钥md5
  let public_key_use // 客户端公钥
  let private_key // 客户端私钥
  let key // ASE密钥
  if (options.encryptionType) {
    let params
    if (options.encryptionType == 1) {
      // 请求获取公匙
      let res = await getPublicKey()
      server_public_key = res.data.public
      // console.log('服务端获取的公钥 = >', server_public_key);
      server_public_key_md5 = md5(server_public_key)
      // console.log('服务端公钥md5 = >', server_public_key);

      // 客户端公私钥
      const obj = await getRsaKeys()
      public_key_use = obj.publicKey
      // console.log('客户端公钥 = >', public_key_use);
      private_key = obj.privateKey
      // console.log('客户端私钥 = >', private_key);

      // ASE密钥
      let AESOBJ = AESEncrypt(JSON.stringify(options.params || options.data))
      // console.log('客户端AES密钥 = >', AESOBJ);
      key = RSADecryptRSA(server_public_key, AESOBJ.key)

      let data = AESOBJ.crypted
      params = {
        public_key: public_key_use,
        pubKeyMd5: server_public_key_md5,
        random: key,
        data,
      }
    } else if (options.encryptionType == 2) {
      let res = await getPublicKey()
      server_public_key = res.data.public
      // console.log('服务端公钥 = >', server_public_key);

      server_public_key_md5 = md5(server_public_key)
      // console.log('服务端公钥md5 = >', server_public_key);

      let AESOBJ = AESEncrypt(JSON.stringify(options.params || options.data))
      // console.log('客户端AES密钥 = >', AESOBJ.key);

      key = RSADecryptRSA(server_public_key, AESOBJ.key)

      let data = AESOBJ.crypted
      params = {
        pubKeyMd5: server_public_key_md5,
        random: key,
        data
      }
    } else if (options.encryptionType == 3) {
      // 获取客户端公私钥
      const obj = await getRsaKeys()
      public_key_use = obj.publicKey
      // console.log('客户端公钥 = >', public_key_use);
      private_key = obj.privateKey
      // console.log('客户端私钥 = >', private_key);
      let data = options.params || options.data
      params = {
        public_key: public_key_use,
        ...data
      }
    }
    if (options.hasOwnProperty('params')) {
      options.params = params
    } else {
      options.data = params
    }
    // console.log({
    //   server_public_key, // 服务端公钥
    //   server_public_key_md5, // 服务端公钥md5
    //   public_key_use, // 客户端公钥
    //   private_key, // 客户端私钥
    //   key // ASE密钥
    // })
    return {
      server_public_key, // 服务端公钥
      server_public_key_md5, // 服务端公钥md5
      public_key_use, // 客户端公钥
      private_key, // 客户端私钥
      key // ASE密钥
    }
  }
}
// 判断是哪种解密
function funDecryptEncryption(options, private_key, data) {
  // 返回参数需要解密
  if (options.encryptionType && options.encryptionType != 2 && data.data) {
    // console.log("data",data)
    // 使用客户端私钥解密后端返回的用客户端公钥加密的服务端AES_key
    const res_AES_key = RSAEncrypt(private_key, data.sign)
    // console.log(res_AES_key, data.data)
    // 使用AES_key解密data数据
    data.data = JSON.parse(AESDecrypt(res_AES_key, data.data))
    // console.log("解密",data.data)
  }
}

export { funEncryption, funDecryptEncryption }
