import axios from "axios";
import router from "@/router";
import { funEncryption, funDecryptEncryption } from "./requestConfig";
import { baseURL } from "@/global";
import { ElMessage, ElLoading } from "element-plus";

let loading;
function startLoading() {
  loading = ElLoading.service({
    lock: true,
  });
}
function endLoading() {
  loading.close();
}
export default function $axios(options) {
  return new Promise(async (resolve, reject) => {
    const service = axios.create({
      baseURL,
      timeout: 30000,
    });

    let token;
    let private_key;
    // 传入请求参数进行加密
    // 并拿到客户端私钥用于下面响应解密
    if (options.encryptionType) {
      let obj = await funEncryption(options);
      private_key = obj.private_key;
    }
    // request 拦截器
    service.interceptors.request.use(
      (config) => {
        return config;
      },

      (error) => {
        // 请求错误时
        // 1. 判断请求超时
        if (
          error.code === "ECONNABORTED" &&
          error.message.indexOf("timeout") !== -1
        ) {
          console.log("timeout请求超时");
          return service.request(options); // 再重复请求一次
        }
        return Promise.reject(error); // 在调用的那边可以拿到(catch)你想返回的错误信息
      }
    );

    // response 拦截器
    service.interceptors.response.use(
      (res) => {
        let data;
        // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
        if (res.data == undefined) {
          data = JSON.parse(res.request.responseText);
        } else {
          data = res.data;
        }

        if (res.data.errno) {
          ElMessage.warning(res.data.msg);
        }
        return res.data;
      },
      (err) => {
        return Promise.reject(err);
      }
    );
    // 请求处理
    service(options)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
